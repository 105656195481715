/* eslint-disable object-curly-newline */
import Vue from 'vue';
// eslint-disable-next-line import/no-named-default
import { createLogger, default as Vuex } from 'vuex';
import YAML from 'yaml';
import { forEach, keyBy, noop } from 'lodash';
import openbitlab, { RootState } from '@/types.d';
import api from '../api';
import UiModule from './modules/ui';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: true,
    projects: {},
    tools: {},
    services: {},
  },
  mutations: {
    setLoading(state, { value }) {
      state.loading = value ?? true;
    },
    setProjects(state, { projects }) {
      if (projects) {
        state.projects = { ...state.projects, ...projects };
      }
    },
    setTools(state, { tools }) {
      if (tools) {
        state.tools = { ...state.tools, ...tools };
      }
    },
    setServices(state, { services }) {
      if (services) {
        state.services = { ...state.services, ...services };
      }
    },
  },
  actions: {
    async newLoadData(context) {
      context.commit('setLoading', { value: true });
      const data: openbitlab.YamlData = YAML.parse(await api.getData());
      const projects = keyBy(data.projects, 'id');
      const tools = keyBy(data.tools, 'name');
      const services = keyBy(data.services, 'name');

      context.commit('setProjects', { projects });
      context.commit('setTools', { tools });
      context.commit('setServices', { services });
      context.commit('setLoading', { value: false });
    },
  },
  modules: {
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [process.env.NODE_ENV !== 'production' ? createLogger() : noop],
});

export const uiModule = new UiModule({ store, name: 'ui' });

export default store;
