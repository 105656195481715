<template lang="pug">
v-parallax(src="@/assets/bg-maininfo.png" height="300").text--white
  v-row(justify="center" align="center")
    v-col(cols='10' md='8' lg="8" xl="4")
      p.display-2.font-weight-black Openbitlab
      span.subtitle-1 POS Validator in <b>{{ getProjectNumber() }}</b> blockchains
      p.display-1.font-weight-bold.mt-5 Proudly staking your assets since 2016
      //- p.display-1.mt-5
      //-   vue-typer(:text='headerTexts'
      //-             :repeat='Infinity'
      //-             :shuffle='false'
      //-             initial-action='typing'
      //-             :pre-type-delay='50'
      //-             :type-delay='50'
      //-             :pre-erase-delay='1000'
      //-             :erase-delay='200'
      //-             erase-style='select-all'
      //-             :erase-on-complete='false'
      //-             caret-animation='blink')
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { filter, values } from 'lodash';

@Component({
  components: {
  },
})
export default class MainHeader extends Vue {
  @State('projects') projects!: openbitlab.RootState['projects']

  private headerTexts: Array<string> = [
    'Automatic Payouts',
    'Ledger Friendly',
  ]

  getProjectNumber(): number {
    return filter(values(this.projects), (p) => p.status !== 'past').length;
  }
}
</script>

<style lang="scss" scope>
.vue-typer {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
.vue-typer .custom.char.typed {
  color: white;
}
</style>
