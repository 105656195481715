<template lang="pug">
  div
    // Desktop Menu
    v-app-bar#nav.indigo.darken-3(app elevate-on-scroll absolute)
      router-link(to='/').d-flex.justify-start.vertical
        v-img.mr-4(
          alt='Openbitlab Logo' contain='' src='@/assets/logo_noback.svg'
          transition='scale-transition' height='60' width='60')

        //h1.white--text.mt-1.mr-5.hidden-sm-and-down
        h1.white--text.mt-1.mr-5.hidden-md-and-up Openbitlab

      //- v-divider(vertical light inset).hidden-sm-and-down

      v-spacer.hidden-sm-and-down

      //- a(target="_blank", href="https://docs.openbitlab.com")
      //-   v-btn()
      //-     v-icon mdi-content-copy
      //-     span.ml-2 Docs

      //- a(href="/networks")
      //-   v-btn()
      //-     v-icon mdi-widgets
      //-     span.ml-2 Networks

      div
        span &nbsp;
        span.hidden-sm-and-down &nbsp;&nbsp;&nbsp;

      //- ContactModal

      ThemeSelector.hidden-sm-and-down

      v-progress-linear(:active="loading" :indeterminate="true"
        absolute bottom color="red")
</template>

<script lang="ts">
// import 'reflect-metadata';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ThemeSelector from '@/components/ThemeSelector.vue';
import ContactModal from '@/components/ContactModal.vue';

@Component({
  components: {
    ContactModal,
    ThemeSelector,
  },
  computed: {
    ...mapState(['loading']),
  },
})
export default class HeaderBar extends Vue {}
</script>

<style lang="scss">
#nav {
  a {
    text-decoration: none;
  }
}
</style>
