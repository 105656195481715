<template lang="pug">
v-row(justify="center" align="start").ma-4
  v-col(cols='12' md='8' xl='8')
    v-row.md-12(style="height: 3em; margin-top: 1em")
      h2 Our tools
    v-row(no-gutters justify="center" align="center")
      v-col.pa-1(v-for="tool in getTools()" :key="tool.name")
        ToolBadge(:t="tool.name")
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ToolBadge from '@/components/Utilities/ToolBadge.vue';
import openbitlab from '@/types.d';
import { values } from 'lodash';

@Component({
  components: {
    ToolBadge,
  },
})
export default class Tools extends Vue {
  @State('tools') tools!: openbitlab.RootState['tools']

  getTools(): Array<openbitlab.Tool> {
    return values(this.tools);
  }
}
</script>
