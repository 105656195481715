<template>
  <div class="twitter-timeline-container">
    <a class="twitter-timeline" data-theme="dark" href="https://twitter.com/Openbitlab_node?ref_src=twsrc%5Etfw">
      Tweets by Openbitlab_node</a>
  </div>
</template>

<style scoped>
.twitter-timeline-container {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { values } from 'lodash';

@Component({})
export default class TwitterTimeline extends Vue {
  private loaded = false;

  mounted() {
    if (typeof window !== 'undefined' && !(window as any).twttrWidgetsLoaded) {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.setAttribute('charset', 'utf-8');
      script.async = true;
      document.head.appendChild(script);
      (window as any).twttrWidgetsLoaded = true;

      this.loaded = true;
    }
  }
}
</script>
