<template lang="pug">
  v-row(justify="center" align="start").ma-4
    v-col(cols='12' md='8' xl='8')
      h2 Networks

    v-col(cols='12' md='8' xl='8')
      v-row(no-gutters justify="center" align="center")
        v-sheet.ma-2.pa-1(rounded, v-for="project in getProjects('main')" :key="project.id")
          v-list-item(:href='"/network/" + project.id + "/" + project.name' two-line='')
            v-avatar.mr-4
              v-img(:src='getLogoPath(project)')
            v-list-item-content
              v-list-item-title.text-h5.mb-1 {{ project.name }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { filter, values, get } from 'lodash';

@Component({
  components: {
  },
})
export default class NetworksView extends Vue {
  @State('projects') projects!: openbitlab.RootState['projects'];

  networks: Array<{key: string, name: string}> = [
    { key: 'main', name: 'Mainnet' },
    { key: 'test', name: 'Testnet' },
  ];

  getProjects(network: string): Array<openbitlab.Project> {
    return filter(values(this.projects), (p) => p.status !== 'past' && p.network === network);
  }

  // eslint-disable-next-line class-methods-use-this
  getLogoPath(prj: openbitlab.Project): string {
    const avatar = get(prj, 'logo', 'none.png');
    return `/data/logos/${avatar}`;
  }
}
</script>
