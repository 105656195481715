<template lang="pug">
v-row(justify="center" align="start").ma-4
  v-col(v-for="network in networks" :key="network.key" cols='12' md='8' xl='8')
    v-row.md-12(style="height: 3em; margin-top: 1em")
      h2 {{network.name}} ({{ getProjects(network.key).length }})
    v-row(no-gutters justify="center" align="center")
      v-sheet.ma-2.pa-1(rounded, v-for="project in getProjects(network.key)" :key="project.id")
        v-list-item(:href='"/network/" + project.id + "/" + project.name' two-line='')
          v-avatar.mr-4
            v-img(:src='getLogoPath(project)')
          v-list-item-content
            v-list-item-title.text-h5.mb-1 {{ project.name }}
      //- v-col.pa-1(v-for="project in getProjects(network.key)" :key="project.id")
      //-   ProjectBadge(:p="project.id")

  v-col(cols='12' md='8' xl='8')
    v-row.md-12(style="height: 3em; margin-top: 1em")
      h2 Past ({{ getPastProjects().length }})
    v-row(no-gutters justify="center" align="center")
      //- v-col.pa-1(v-for="project in getPastProjects()" :key="project.id")
      //-   ProjectBadge(:p="project.id")

      v-sheet.ma-2.pa-1(rounded, v-for="project in getPastProjects()" :key="project.id")
        v-list-item(:href='"/network/" + project.id + "/" + project.name' two-line='')
          v-avatar.mr-4
            v-img(:src='getLogoPath(project)')
          v-list-item-content
            v-list-item-title.text-h5.mb-1 {{ project.name }}

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ProjectBadge from '@/components/Utilities/ProjectBadge.vue';
import openbitlab from '@/types.d';
import { filter, get, values } from 'lodash';

@Component({
  components: {
    ProjectBadge,
  },
})
export default class Projects extends Vue {
  @State('projects') projects!: openbitlab.RootState['projects']

  networks: Array<{key: string, name: string}> = [
    { key: 'main', name: 'Mainnet' },
    { key: 'test', name: 'Testnet' },
  ];

  getPastProjects(): Array<openbitlab.Project> {
    return filter(values(this.projects), (p) => p.status === 'past');
  }

  // eslint-disable-next-line class-methods-use-this
  getLogoPath(prj: openbitlab.Project): string {
    const avatar = get(prj, 'logo', 'none.png');
    return `/data/logos/${avatar}`;
  }

  getProjects(network: string): Array<openbitlab.Project> {
    return filter(values(this.projects), (p) => p.status !== 'past' && p.network === network);
  }
}
</script>
