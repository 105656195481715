<template lang="pug">
v-row(justify="center" align="start").ma-4
  v-col(cols='12' md='8' xl='8')
    v-row.md-12(style="height: 3em; margin-top: 1em")
      h2 Our services
    v-row(no-gutters justify="center" align="center")
      v-col.pa-1(v-for="service in getServices()" :key="service.name")
        ServiceBadge(:s="service.name")
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ServiceBadge from '@/components/Utilities/ServiceBadge.vue';
import openbitlab from '@/types.d';
import { filter, values } from 'lodash';

@Component({
  components: {
    ServiceBadge,
  },
})
export default class Services extends Vue {
  @State('services') services!: openbitlab.RootState['services']

  getServices(): Array<openbitlab.Service> {
    return filter(values(this.services), (s) => s.type !== 'docs');
  }
}
</script>
