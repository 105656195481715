<template lang="pug">
v-sheet.mx-auto(v-if="tool" elevation="6" rounded='xl' min-width="200" max-width="600")
  v-list-item(two-line='')
    v-avatar.mr-4
      v-icon(large) mdi-github
    v-list-item-content
      v-list-item-title.text-h5.mb-1 {{ tool.name }}
      v-list-item-title.text-p.mb-1 {{ tool.description }}
      v-list-item-subtitle
        v-chip(small color="secondary" :href="tool.website" target="_blank")
          v-icon(left small) mdi-link-variant
          span.text-truncate {{ tool.website }}
        //- br
        //- br
        //- v-chip(small color="secondary" :href="tool.repository" target="_blank")
        //-   v-icon(left small) mdi-github
        //-   span.text-truncate {{ tool.repository }}

</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import CopyButton from '@/components/Utilities/CopyButton.vue';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { get } from 'lodash';

@Component({
  components: {
    CopyButton,
  },
})
export default class ToolBadge extends Vue {
  @State('tools') tools!: openbitlab.RootState['tools']

  @Prop({ required: true }) readonly t!: string

  get tool(): openbitlab.Tool {
    return get(this.tools, this.t);
  }
}
</script>

<style>
.v-chip + .v-chip {
  margin-left: 5px;
}
</style>
