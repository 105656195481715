<template lang="pug">
    v-row(justify="center" align="start").ma-4
        v-col(cols='12' md='8' xl='8')
            br
            div(v-if="project" justify="center" align="center")
                v-avatar
                    v-img(:src='getLogoPath()')
                br
                h1 {{ project.name }}
                  span(v-if="project.network == 'test'") &nbsp;Testnet

                go-back-button(:uri="'/network/' + $route.params.id + '/' + $route.params.name")

                v-chip(small color="secondary" :href="project.website" target="_blank")
                  v-icon(left small) mdi-link-variant
                  span.text-truncate {{ project.website }}
                v-chip(v-for="validator in project.validator" small color="secondary" :href="validator.explorer" target="_blank")
                  v-icon(left small) mdi-account-check
                  span.text-truncate {{ validator.name }}
                v-chip(v-if="project.validator.length > 0 && project.validator[0].fee" small color="secondary" target="_blank")
                  v-icon(left small) mdi-credit-card
                  span.text-truncate {{ project.validator[0].fee }}%
                br
                br
            hr

        SnapshotService(:project="project")
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { get, values } from 'lodash';
import SnapshotService from '@/components/SingleNetwork/SnapshotService.vue';
import GoBackButton from '@/components/SingleNetwork/GoBackButton.vue';

@Component({
  components: { SnapshotService, GoBackButton },
})
export default class SingleNetworkSnapshotView extends Vue {
  @State('projects') projects!: openbitlab.RootState['projects'];

  public project: any = null;

  @Watch('projects', { immediate: true, deep: true })
  updatedProjects(projects: openbitlab.RootState['projects']) {
    this.project = this.projects[this.$route.params.id];
  }

  // eslint-disable-next-line class-methods-use-this
  getLogoPath(): string {
    const avatar = get(this.projects[this.$route.params.id], 'logo', 'none.png');
    return `/data/logos/${avatar}`;
  }
}
</script>
