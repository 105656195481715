<template lang="pug">
a(:href="uri")
  v-btn.goback.mx-2.darken-3(fab, dark, small, color="orange")
    v-icon(dark)
      | mdi-arrow-left
</template>

<style lang="scss">
.goback {
  position: absolute;
  top: 1em;
  left: 1em;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class GoBackButton extends Vue {
  @Prop({ required: true }) readonly uri!: string;
}
</script>
