<template lang="pug">
v-row(justify="center" align="center").ma-10

  v-col(cols='12' md='3' xl='2')
    v-avatar(color='blue darken-4' size="80")
      v-icon(dark large)
        | mdi-credit-card-clock
    h2.mt-4.mb-2 Periodic Redistributions
    p.text-subtitle-1 Rewards are automatically redistributed to voters, no need to manually ask for payments.

  v-col(cols='12' md='3' xl='2')
    v-avatar(color='blue darken-4' size="80")
      v-icon(dark large)
        | mdi-shield-check
    h2.mt-4.mb-2 Trusted and Reputable
    p.text-subtitle-1 We’re a staking infrastructure provider that deploys its technology on several PoS blockchains.

  v-col(cols='12' md='3' xl='2')
    v-avatar(color='blue darken-4' size="80")
      v-icon(dark large)
        | mdi-server-network
    h2.mt-4.mb-2 Secure Architecture
    p.text-subtitle-1 We have a secure and highly reliable staking infrastructure, with 24/7 monitoring tools.

  v-col(cols='12' md='3' xl='2')
    v-avatar(color='blue darken-4' size="80")
      v-icon(dark large)
        | mdi-sort-clock-descending-outline
    h2.mt-4.mb-2 High Uptime
    p.text-subtitle-1 We offer the best guarantees on availability. Never miss a block reward.
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Info extends Vue {
  private height = 300;
}
</script>

<style>
  p.text-subtitle-1 {
    color: #7e8299;
    height: 4em;
  }
</style>
