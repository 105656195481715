<template lang="pug">
  v-sheet.mx-auto(v-if="service" elevation="6" rounded='xl' min-width="200" max-width="600")
    v-list-item(v-if="service.type === 'snapshots'" two-line='' :href="service.chain + '/snapshot'")
      v-avatar.mr-4
        v-img(:src='getLogoPath()')
      v-list-item-content
        v-list-item-title.text-h5.mb-4.mt-2 {{ service.name }}
        v-list-item-title.text-p.mb-2 {{ service.description }}
    v-list-item(v-else-if="service.type === 'rpc' || service.type === 'relayer'" two-line='')
      v-avatar.mr-4
        v-img(:src='getLogoPath()')
      //- v-avatar.mr-4
      //-   v-icon(large) mdi-server
      v-list-item-content
        v-list-item-title.text-h5.mb-1 {{ service.name }}
        v-list-item-title.text-p.mb-1 {{ service.description }}
        v-list-item-subtitle
          v-chip(small color="secondary" :href="service.url" target="_blank")
            v-icon(left small) mdi-link-variant
            span.text-truncate {{ service.url }}
    v-list-item(v-else-if="service.type === 'docs'" two-line='' :href="service.chain + '/docs'")
      v-avatar.mr-4
        v-img(:src='getLogoPath()')
      v-list-item-content
        v-list-item-title.text-h5.mb-4.mt-2 {{ service.name }}
        v-list-item-title.text-p.mb-2 {{ service.description }}
    v-list-item(v-else two-line='')
      v-avatar.mr-4
        v-img(:src='getLogoPath()')
      //- v-avatar.mr-4
      //-   v-icon(large) mdi-server
      v-list-item-content
        v-list-item-title.text-h5.mb-4.mt-2 {{ service.name }}
        v-list-item-title.text-p.mb-2 {{ service.description }}
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import CopyButton from '@/components/Utilities/CopyButton.vue';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { get } from 'lodash';

@Component({
  components: {
    CopyButton,
  },
})
export default class ServiceBadge extends Vue {
  @State('services') services!: openbitlab.RootState['services']

  @Prop({ required: true }) readonly s!: string

  get service(): openbitlab.Service {
    return get(this.services, this.s);
  }

  public getLogoPath(): string {
    const avatar = get(this.service, 'chain', 'none.png');
    return `/data/logos/${avatar.toLowerCase()}.png`;
  }
}
</script>

<style>
.v-chip + .v-chip {
  margin-left: 5px;
}
.v-list-item:hover {
  border-radius: 24px !important;
}
</style>
