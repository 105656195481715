<template lang="pug">
  v-row(justify="center" align="start").ma-4
    v-col(cols='12' md='8' xl='8')
      br
      div(justify="center" align="center")
        v-avatar
          v-img(:src='getLogoPath()')
        br
        h1 {{ projectName }} Docs
        br

      hr
      go-back-button(:uri="'/network/' + $route.params.id + '/' + $route.params.name")

      v-tabs(v-model="tab", background-color="transparent", color="basil", grow)
        v-tab(v-for="page in pages", :key="page") {{ page }}
      v-tabs-items(v-model="tab")
        v-tab-item(v-for="page in pages", :key="page")
          div
            markdown-renderer(v-if="projectNetwork === 'main'" :file-name="'docs/' + projectName.toLowerCase() + '/' + page + '.md'")
            markdown-renderer(v-else :file-name="'docs/testnet/' + projectName.toLowerCase() + '/' + page + '.md'")
</template>

<style>
.goback {
  position: absolute;
  top: 1em;
  left: 1em;
}
</style>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { filter, get, values } from 'lodash';
import MarkdownRenderer from '@/components/SingleNetwork/MarkdownRenderer.vue';
import GoBackButton from '@/components/SingleNetwork/GoBackButton.vue';

@Component({
  components: { MarkdownRenderer, GoBackButton },
})
export default class SingleNetworkDocsView extends Vue {
  @State('projects') projects!: openbitlab.RootState['projects'];

  @State('services') services!: openbitlab.RootState['services'];

  public pages: Array<string> = [];

  public projectName = '';

  public projectNetwork = '';

  public tab = 'main';

  @Watch('projects', { immediate: true, deep: true })
  updatedProjects(projects: openbitlab.RootState['projects']) {
    this.projectName = this.projects[this.$route.params.id].name;
    this.projectNetwork = this.projects[this.$route.params.id].network;

    const service = filter(values(this.services), (s) => s.chain === this.projectName && s.type === 'docs')[0];
    if (service.pages) {
      this.pages = service.pages;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getLogoPath(): string {
    const avatar = get(this.projects[this.$route.params.id], 'logo', 'none.png');
    return `/data/logos/${avatar}`;
  }
}
</script>
