<template lang="pug">
  mixin Copyright
    v-icon(left='').mb-1 mdi-copyright
    span
      | {{ new Date().getFullYear() }} &mdash;
      |
      strong Openbitlab

  v-footer(padless='').mt-4
      v-card.blue.darken-4.white--text.text-center(flat='' tile='' width="100%")
        v-card-text
          v-row(justify="center" align="center")
            v-col(cols='12' sm='12' md='6' lg="6" xl="6")
              v-btn.mx-2.white--text(v-for='s in socials' :key='s.icon' icon='' :href='s.link' target="_blank")
                v-icon(size='24px')
                  | {{ s.icon }}
            v-col(cols='12' sm='12' md='6' lg="6" xl="6")
              +Copyright()

  //- v-footer(padless='').mt-4
  //-     v-card.blue.darken-4.white--text.text-center(flat='' tile='' width="100%")
  //-       v-card-text
  //-         v-btn.mx-2.white--text(v-for='s in socials' :key='s.icon' icon='' :href='s.link' target="_blank")
  //-           v-icon(size='24px')
  //-             | {{ s.icon }}
  //-       v-divider
  //-       v-card-text.white--text
  //-         +Copyright()

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { TelegramIcon } from 'vue-simple-icons';

@Component({
  components: {
    TelegramIcon,
  },
})
export default class FooterBar extends Vue {
  private socials: Array<{ icon: string, link: string}> = [
    { icon: 'mdi-twitter', link: 'https://twitter.com/Openbitlab_node' },
    { icon: 'mdi-github', link: 'https://github.com/openbitlab' },
  ]
}
</script>

<style lang="scss">
#footer {
  a {
    text-decoration: none;
  }
}

.invert-color {
  filter: invert(1);
}
</style>
