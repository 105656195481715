<template lang="pug">
v-sheet.mx-auto(v-if="project" elevation="6" rounded='xl' min-width="200" max-width="600"
  @mouseover="hovered=true" @mouseout="hovered=false")
  v-list-item(two-line='')
    v-avatar.mr-4
      v-img(:src='getLogoPath()')
    v-list-item-content
      v-list-item-title.text-h5.mb-1 {{ project.name }}
      <transition name="fade">
        v-list-item-subtitle(v-if="hovered")
          v-chip(small color="secondary" :href="project.website" target="_blank")
            v-icon(left small) mdi-link-variant
            span.text-truncate {{ project.website }}
          v-chip(v-if="project.validator.length > 0" small color="secondary" :href="project.validator[0].explorer" target="_blank")
            v-icon(left small) mdi-account-check
            span.text-truncate {{ project.validator[0].name }}
          v-chip(v-if="project.validator.length > 0" small color="secondary" target="_blank")
            v-icon(left small) mdi-credit-card
            span.text-truncate {{ project.validator[0].fee }}%
      </transition>
    v-chip(v-if="project.validator.length > 1" small color="blue darken-4" text-color="white").ml-4.mr-2.pa-4
      v-icon(left) mdi-account
      span {{ project.validator.length }}

</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import CopyButton from '@/components/Utilities/CopyButton.vue';
import { State } from 'vuex-class';
import openbitlab from '@/types.d';
import { get } from 'lodash';

@Component({
  components: {
    CopyButton,
  },
})
export default class ProjectBadge extends Vue {
  @State('projects') projects!: openbitlab.RootState['projects']

  @Prop({ required: true }) readonly p!: string

  private hovered = false;

  get project(): openbitlab.Project {
    return get(this.projects, this.p);
  }

  public getLogoPath(): string {
    const avatar = get(this.project, 'logo', 'none.png');
    return `/data/logos/${avatar}`;
  }
}
</script>

<style>
/* Define the "fade" transition */
/* .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */
.v-chip + .v-chip {
  margin-left: 5px;
}
</style>
