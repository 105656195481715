<template lang="pug">
  div
    v-tooltip(v-if='darkMode' bottom='')
      template(v-slot:activator='{ on }')
        v-btn(v-on='on' x-small fab @click='toggleDarkMode')
          v-icon(small).mr-1 mdi-moon-waxing-crescent
      span Dark Mode On
    v-tooltip(v-else bottom='')
      template(v-slot:activator='{ on }')
        v-btn(v-on='on' x-small fab @click='toggleDarkMode')
          v-icon(small color='yellow') mdi-white-balance-sunny
      span Dark Mode Off
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { uiModule } from '../store';

@Component({})
export default class ThemeSelector extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get darkMode(): boolean {
    return uiModule.darkMode;
  }

  // eslint-disable-next-line class-methods-use-this
  public toggleDarkMode(): void {
    uiModule.toggleDarkMode();
    this.$vuetify.theme.dark = uiModule.darkMode;
  }
}
</script>
