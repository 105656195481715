<template lang="pug">
div
  .markdown-container(v-html="renderedMarkdown")
</template>

<style lang="scss">
.markdown-container {
  padding: 1em;
  text-align: left;
}

.markdown-container code {
  font-size: 100%;
  padding: 1em;
  display: block;
  overflow: scroll;
}

pre {
  margin: 0.7em 0;
}

h1,h2,h3,h4,h5 {
  padding: unset;
  padding: 0.3em 0;
  margin: unset;
}

p {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { marked } from 'marked';

@Component({
  components: { },
})
export default class MarkdownRenderer extends Vue {
  @Prop({ required: true }) readonly fileName!: string;

  public renderedMarkdown: string | Promise<string> = '';

  mounted() {
    this.loadMarkdown();
  }

  loadMarkdown() {
    console.log(`Loading markdown file: ${this.fileName}`);

    fetch(`/data/${this.fileName}`)
      .then((response) => response.text())
      .then((text) => {
        this.renderedMarkdown = marked(text);
      })
      .catch((error) => {
        console.error('Error loading markdown file:', error);
      });
  }
}
</script>
