import { render, staticRenderFns } from "./TwitterTimeline.vue?vue&type=template&id=0c087fbe&scoped=true"
import script from "./TwitterTimeline.vue?vue&type=script&lang=ts"
export * from "./TwitterTimeline.vue?vue&type=script&lang=ts"
import style0 from "./TwitterTimeline.vue?vue&type=style&index=0&id=0c087fbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c087fbe",
  null
  
)

export default component.exports