<template lang="pug">
span
  template(v-if="showResult")
    // Success
    v-btn(v-if="isSuccess" v-bind="$attrs" color="success")
      v-icon mdi-check
    // Failure
    v-btn(v-else v-bind="$attrs" color="danger")
      v-icon mdi-close
  template(v-else)
    v-btn(v-bind="$attrs"
      v-clipboard:copy="textToCopy"
      v-clipboard:success="onCopy"
      v-clipboard:error="onCopyError")
        v-icon(v-if="buttonIcon") {{ buttonIcon }}
        v-icon(v-else) mdi-content-copy
        span.ml-2(v-if="buttonText") {{ buttonText }}
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';

@Component({})
export default class CopyButton extends Vue {
  @Prop({ required: true }) readonly textToCopy!: string

  @Prop({ required: false }) readonly buttonIcon!: string

  @Prop({ required: false }) readonly buttonText!: string

  private showResult = false;

  private isSuccess = false;

  // eslint-disable-next-line class-methods-use-this
  public onCopy(): void {
    this.isSuccess = true;
    this.showResult = true;
    _.delay(() => { this.showResult = false; }, 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  public onCopyError(): void {
    this.isSuccess = false;
    this.showResult = true;
    _.delay(() => { this.showResult = false; }, 1000);
  }
}
</script>
