<template lang="pug">
  div
    MainHeader
    //- MarketBanner
    v-divider
    Info
    v-divider.mb-4
    Projects
    v-divider.mb-4
    Tools
    //- v-divider.mb-4
    //- Services
    //- Hidden because twitter feed stopped working
    //- v-divider.mb-4
    //- TwitterTimeline
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/components/Home/MainHeader.vue';
import Info from '@/components/Home/Info.vue';
import Projects from '@/components/Home/Projects.vue';
import Tools from '@/components/Home/Tools.vue';
import Services from '@/components/Home/Services.vue';
import TwitterTimeline from '@/components/Home/TwitterTimeline.vue';
// import MarketBanner from '@/components/Home/MarketBanner.vue';

@Component({
  components: {
    MainHeader, Info, Projects, Tools, Services, TwitterTimeline,
    // MarketBanner,
  },
})
export default class HomeView extends Vue {}
</script>
