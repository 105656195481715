<template lang="pug">
v-col(cols='12' md='8' xl='8')
  h2 Tools and Services
  br
  v-col()
      v-row(no-gutters justify="center" align="center")
          v-col.ma-2.pa-1(v-for="service in getServices()" :key="service.name")
              ServiceBadge(:s="service.name")

          v-col.ma-2.pa-1(v-for="tool in getTools()" :key="tool.name")
            ToolBadge(:t="tool.name")
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ToolBadge from '@/components/Utilities/ToolBadge.vue';
import openbitlab from '@/types.d';
import { filter, values } from 'lodash';
import ServiceBadge from '../Utilities/ServiceBadge.vue';

@Component({
  components: {
    ToolBadge,
    ServiceBadge,
  },
})
export default class ToolsAndServices extends Vue {
  @Prop({ required: true }) readonly project!: openbitlab.Project;

  @State('services') services!: openbitlab.RootState['services']

  @State('tools') tools!: openbitlab.RootState['tools']

  getServices(): Array<openbitlab.Service> {
    return filter(values(this.services), (s) => s.chain === this.project.name && (s.network === this.project.network));
  }

  getTools(): Array<openbitlab.Tool> {
    return filter(values(this.tools), (t) => t.project === undefined || t.project === this.project.name);
  }
}
</script>
