import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import SingleNetworkDocsView from '@/views/SingleNetworkDocsView.vue';
import SingleNetworkSnapshotView from '../views/SingleNetworkSnapshotView.vue';
import SingleNetworkView from '../views/SingleNetworkView.vue';
import NetworksView from '../views/NetworksView.vue';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/networks',
    name: 'NetworksView',
    component: NetworksView,
  },
  {
    path: '/network/:id/:name',
    name: 'SingleNetworkView',
    component: SingleNetworkView,
  },
  {
    path: '/network/:id/:name/snapshot',
    name: 'SingleNetworkSnapshotView',
    component: SingleNetworkSnapshotView,
  },
  {
    path: '/network/:id/:name/docs',
    name: 'SingleNetworkDocsView',
    component: SingleNetworkDocsView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
